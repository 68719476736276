@import '../../styles/utils.scss';

.el {
    padding-bottom: 40px;
    // border-bottom: 1px solid $color-blue-25;
    max-width: $container-fhd;
    margin: 40px auto 0;
    @include tablet {
      margin-top: 0;
    }
    &.people {
      padding-top: 20px;
      background-color: #F7F7F7;
    }
    .container {
      max-width: $container-md;
    }
  .title {
    text-align: left;
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 40px;
    }
}
.content{
  .items{
      display: flex;
      flex-wrap: wrap;
      margin-left: -18px;
      margin-right: -18px;
      @include tablet {
        padding: 0 10px;
      }
      .image{
        display: block;
        justify-content: center;
        align-items: center;
        margin: 0 18px 40px;
        width: calc(25% - 36px);
        background-color: $color-blue;
        text-align: center;
        position: relative;
        @include tablet {
          margin: 0 15px 40px;
          width: calc(33.3% - 30px);
        }
        @include mobile {
          margin: 0 10px 20px;
          width: calc(50% - 20px);
        }
      }
  }

}
.cta {
    // margin-bottom: 60px;
    border-bottom: 1px solid $color-blue-25;
    display: flex;
    justify-content: flex-end;
    :global(.container) {
      max-width: $container-md;
      display: flex;
      justify-content: flex-end;
    }
    .ctaLink {
      padding: 20px 0 20px 40px;
      border-left: 1px solid $color-blue-25;
    }
    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
}
