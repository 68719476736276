@import '../../styles/utils.scss';

.el {
  padding-top: 40px;
  width: 100%;
  overflow: hidden;
  max-width: $container-fhd;
  margin: 0 auto;
  @include tablet {
    margin-top: 40px;
    padding-top: 0;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      border-top: 1px solid $color-blue-25;
    }
  }
  .slickArrows {
    display: none;
    margin-bottom: 20px;
    @include tablet {
      display: flex;
      justify-content: flex-end;
    }
  }
  :global(.slick-arrow) {
    font-size: 24px;
    color: $color-orange;
    padding: 10px 8px 6px;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: 0;
    span {
      display: block;
    }
    span:before {
      display: block;
    }
    &:last-child {
      border-left: 1px solid $color-blue-25;
    }
  }
  :global(.slick-prev) {
    right: 40px;
    span:before {
      transform: scaleX(-1);
    }
  }
  :global(.slick-disabled) {
    cursor: default;
    span {
      opacity: 0.2;
    }
  }
  .title {
    margin-bottom: 38px;
    h2 {
    }
  }
  .wrapper {
    display: block;
    :global(.slick-slider) {
      width: 91.74%;
    }
    :global(.slick-list) {
      overflow: visible;
    }
    :global(.slick-slide) {
      width: 48.75%;
      @include tablet {
        height: auto;
        padding-right: 20px;
        & > div {
          height: 100%;
        }
      }
    }
    :global(.regular.slider) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    :global(.slick-track) {
      display: flex;
    }
    @include tablet {
      margin-bottom: 40px;
    }
  }
  .cta {
    // border-bottom: 1px solid $color-blue-25;
    position: relative;
    display: flex;
    justify-content: flex-end;
    // margin-bottom: 129px; PLEASE DO NOT ADD MARGIN TO THE BUTTON, THE SPACING SHOULD BE MANAGED AT GLOBAL CSS
    max-width: $container-md;
    margin: 0 auto;

    .ctaLink {
      padding: 20px 40px 20px 40px;
      border-left: 1px solid $color-blue-25;
    }
    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 4000px;
      height: 0;
      bottom: 0;
      left: calc((100% - 4000px) / 2);
      border-bottom: 1px solid $color-blue-25;
    }
  }
  .reportCard {
    width: 48.75%;
    margin-bottom: 40px;
    @include tablet {
      width: 100%;
      height: 100%;
    }
    @include mobile {
      margin-bottom: 0;
      height: 100%;
    }
  }
}
