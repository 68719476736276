@import '../../styles/utils.scss';

.el {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  position: relative;
  background-color: transparent !important;
  z-index: 10;

  @include mobile {
    max-width: calc(100% - 20px);
  }
  .container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.inner {
  	position: absolute;
  	padding-top: 40px;
    z-index: 1;
  	@include tablet{
  		position: relative;
  		padding-top: 20px;
  		padding-bottom: 20px;
  	}
  }
  a {
    color: $color-blue;
    &[disabled] {
      color: $color-secondary;
    }
  }
  :global(.no-splash) & {
    width: 100%;
    max-width: $container-md;
    // position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 40px;
    @include tablet {
      padding-top: 13px;
    }
    @include mobile {
      position: relative;
      padding-bottom: 16px;
    }
  }
  :global(.splash) & {
    width: 100%;
    max-width: $container-md;
    padding-top: 40px;
    margin: 0 auto;
    @include tablet {
      padding-top: 13px;
    }
    @include mobile {
      position: relative;
      padding-bottom: 16px;
    }
  }
}

:global(.page-modal) {
	.el {
		font-size: 18px;
		@include tablet {
			font-size: 14px;
		}
	}
}
